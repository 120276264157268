import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useExpensesPermissions = function (user) {
    var hasPermissionListExpense = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.OPERATION, MethodCode.LIST);
    var hasPermissionEditExpense = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.OPERATION, MethodCode.EDIT);
    var hasPermissionViewExpense = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.OPERATION, MethodCode.VIEW);
    var hasPermissionDeleteExpense = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.OPERATION, MethodCode.DELETE);
    var hasPermissionCreateExpense = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.OPERATION, MethodCode.CREATE);
    return {
        hasPermissionListExpense: hasPermissionListExpense,
        hasPermissionEditExpense: hasPermissionEditExpense,
        hasPermissionViewExpense: hasPermissionViewExpense,
        hasPermissionDeleteExpense: hasPermissionDeleteExpense,
        hasPermissionCreateExpense: hasPermissionCreateExpense,
    };
};
export default useExpensesPermissions;
